import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, AppConstant, QueueStatusEnum } from 'AppConstant'
import { APIStatus } from '../types'

export interface IQandAPayload {
  id: string
  text: string
  patientId: string
  questionId: string
  question: {
    id: string
    order: number
    text: string
    type: string
    iconIdentifier: string
  }
  responseOptions: ResponseOption[]
}

interface User {
  id: string
  firstname: string
  signupThrough: string
  lastname: string
  phone: string
  unverifiedPhone: string | null
  dob: string
  email: string | null
  unverifiedEmail: string | null
  externalUserId: string | null
  emailVerificationId: string
  welcomeEmailSend: boolean
  profilePic: string | null
  screeningStatus: string
  gender: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}

interface Visit {
  id: string
  stripeCustomerId: string
  screeningStatus: string
  isQuestionareCompleted: boolean
  initialWaiver: boolean
  screenerFeedback: string
  screeningSessionRecording: string | null
  createdAt: string
  updatedAt: string
  user: User
  isNew: boolean
  membership: boolean
  subscriptionExpiresAt: string | null
  membershipStatus: string | null
  scanDue: boolean | null
  nextScanDueIn: number | null
  onDemandCount: number | null
  subscriptionCount: number | null
  feedbackValue: number
  metric: {
    id: string
    rev: null | string
    atd: number
    dst: number
    apm: string
    membershipMonth: number | null
    membershipWeek: number | null
    scanScore: number | null
    createdAt: string
    updatedAt: string
  }
  treatmentType: string | null
}

export interface IPendingNotes {
  id: string
  visitId: string
  chiropractorId: string
  subjective: string
  objective: string
  assessment: string
  plan: string
  status: string
  patient: {
    id: string
    stripeCustomerId: string
    screeningStatus: string
    isQuestionareCompleted: boolean
    screenerFeedback: string
    screeningSessionRecording: string
    user: {
      id: string
      firstname: string
      lastname: string
      phone: string
      dob: string
      email: string
      profilePic: string
      screeningStatus: string
      gender: string
    }
  }
}
interface IQandA {
  type: string
  iconIdentifier: string
  optionText: string[]
  order?: string[] // Assuming order can be an array of strings or undefined
  optionId: string
}
export interface ResponseOption {
  id: string
  answerId: string
  optionId: string
  option: {
    id: string
    text: string
    order: number
  }
}
interface ICurrentPatient {
  patientId: string
  profilePic: string | null
  lastname: string
  firstname: string
  gender: string
  screenerFeedback: string
  dob: string
  visitId: string
  appointmentTime: string
  checkinTime: string
  queueStatus: string
  notesUploadUrl?: string
}

interface ITreatmentType {
  id: string
  treatmentType: string
  screeningStatus: string
}

interface LobbyState {
  currentPatient: ICurrentPatient | null
  queue: Array<any>
  queueStatus: APIStatus
  previousPatients: Array<any>
  previousPatientsStatus: APIStatus
  todayVisitStatus: APIStatus
  todayVisit: Array<any>
  callInPatientStatus: APIStatus
  callInError: string | any
  startChiropracticStatus: APIStatus
  stopChiropracticStatus: APIStatus
  patientQuestionarieStatus: APIStatus
  patientQuestionaries: IQandA[]
  patientQuestionariesModal: IQandA[]
  patientQuestionarieModalStatus: APIStatus
  notes: Array<any>
  notesStatus: APIStatus
  pendingNotes: Array<IPendingNotes>
  pendingNotesStatus: APIStatus
  visit: Visit | null
  visitModal: Visit | null
  visitStatus: APIStatus
  visitModalStatus: APIStatus
  treatmentType: ITreatmentType | null
  treatmentTypeStatus: APIStatus
  checkInPatient: any
  checkInPatientStatus: APIStatus
}

const initialState: LobbyState = {
  currentPatient: null,
  queue: [],
  queueStatus: APIStatus.idle,
  callInPatientStatus: APIStatus.idle,
  callInError: '',
  startChiropracticStatus: APIStatus.idle,
  stopChiropracticStatus: APIStatus.idle,
  patientQuestionarieStatus: APIStatus.idle,
  patientQuestionaries: [],
  notes: [],
  notesStatus: APIStatus.idle,
  pendingNotes: [],
  pendingNotesStatus: APIStatus.idle,
  previousPatients: [],
  previousPatientsStatus: APIStatus.idle,
  todayVisit: [],
  todayVisitStatus: APIStatus.idle,
  visit: null,
  visitModal: null,
  visitStatus: APIStatus.idle,
  visitModalStatus: APIStatus.idle,
  patientQuestionariesModal: [],
  patientQuestionarieModalStatus: APIStatus.idle,
  treatmentType: null,
  treatmentTypeStatus: APIStatus.idle,
  checkInPatient: null,
  checkInPatientStatus: APIStatus.idle,
}

export const loadLobbyQueueAsync = createAsyncThunk(
  'lobby/loadLobbyQueueAsync',
  async ({ clinicId }: any, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/clinic/${clinicId}/lobby?status=${QueueStatusEnum.WAITING}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const pollLobbyQueueAsync = createAsyncThunk(
  'lobby/pollLobbyQueueAsync',
  async ({ clinicId }: any, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/clinic/${clinicId}/lobby?status=${QueueStatusEnum.WAITING}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const loadLobbyPreviousAsync = createAsyncThunk(
  'lobby/loadLobbyPreviousAsync',
  async ({ clinicId, date }: { clinicId: string | undefined; date?: any }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/clinic/${clinicId}/lobby?status=${QueueStatusEnum.COMPLETED}&startTime=${date}T00:00:00.000Z`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const patientQuestionarieAsync = createAsyncThunk(
  'lobby/patientQuestionarieAsync',
  async ({ patientId }: any, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/patient/${patientId}/patient-qes-ans`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)
export const patientVisitAsync = createAsyncThunk('lobby/patientVisitAsync', async ({ patientId }: any, thunkAPI) => {
  //${process.env.REACT_APP_API_BASE_URL || '/api'
  const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

  let response = await axios
    .get(`${API_URL}/patient/${patientId}/chiropractor`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })
    .then((res) => res)
    .catch((err) => err.response)

  if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue(response.data.data)
  } else {
    return thunkAPI.rejectWithValue(response.data.message)
  }
})
export const callInNextPatientAsync = createAsyncThunk(
  'lobby/callInNextPatientAsync',
  async ({ queue }: any, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)
    const [nextPatient, ...restOfThePatient] = queue
    // console.log(nextPatient)
    if (nextPatient) {
      let response = await axios
        .post(
          `${API_URL}/clinic/called-in`,
          {
            visitId: nextPatient.visitId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
            },
          },
        )
        .then((res) => res)
        .catch((err) => err.response)

      if (response.data && response.data.code === 'success') {
        return thunkAPI.fulfillWithValue({
          nextPatient,
          queue: restOfThePatient,
        })
      } else if (response.data && response.data.code === 'already_used_by_another_doctor') {
        return thunkAPI.rejectWithValue({ error: 'calledInTwice', queue: restOfThePatient })
      } else {
        return thunkAPI.rejectWithValue(response.data.message)
      }
    } else {
      return thunkAPI.fulfillWithValue({
        nextPatient: null,
        queue: [],
      })
    }
  },
)
export const startChiropracticAsync = createAsyncThunk(
  'lobby/startChiropracticAsync',
  async (visitId: string | undefined, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    // console.log(nextPatient)
    let response = await axios.post(
      `${API_URL}/clinic/start`,
      {
        visitId: visitId,
        contentType: 'audio/wav',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      },
    )

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const stopChiropracticAsync = createAsyncThunk(
  'lobby/stopChiropracticAsync',
  async (
    {
      queue,
      visitId,
      currentPatient,
    }: { queue?: any; visitId: string | undefined; currentPatient?: ICurrentPatient | null },
    thunkAPI,
  ) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)
    const [nextPatient, ...restOfThePatient] = queue.filter((n: any) => n.visitId !== currentPatient?.visitId)
    // console.log(nextPatient)
    let response = await axios.post(
      `${API_URL}/clinic/stop`,
      {
        visitId: visitId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      },
    )

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const getTodayVisitAsync = createAsyncThunk(
  'lobby/getTodayVisitAsync',
  async ({ timezone }: { timezone: any }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)
    // console.log(nextPatient)
    let response = await axios.get(`${API_URL}/visit/chiropractor?timezone=${timezone}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const postTreatmentType = createAsyncThunk(
  'lobby/postTreatmentType',
  async ({ patientId, data }: { patientId: string; data: any }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)
    // console.log(nextPatient)
    let response = await axios.put(`${API_URL}/patient/${patientId}/treatment-type`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const checkInPatientAsync = createAsyncThunk(
  'lobby/checkInPatientAsync',
  async ({ visitId, clinicId }: { visitId: string; clinicId: string }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .put(
        `${API_URL}/visit/forced-check-in`,
        { visitId: visitId, clinicId: clinicId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
          },
        },
      )
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

// Reducers for Modal

export const patientQuestionarieModalAsync = createAsyncThunk(
  'lobby/patientQuestionarieModalAsync',
  async ({ patientId }: any, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/patient/${patientId}/patient-qes-ans`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const patientVisitModalAsync = createAsyncThunk(
  'lobby/patientVisitModalAsync',
  async ({ patientId }: any, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/patient/${patientId}/chiropractor`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

const lobbySlice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    resetStartChiropracticStatus: (state) => {
      state.startChiropracticStatus = APIStatus.idle
    },
    resetStopChiropracticStatus: (state) => {
      state.stopChiropracticStatus = APIStatus.idle
    },
    resetCallInPatientStatus: (state) => {
      state.callInPatientStatus = APIStatus.idle
    },
    resetCalledInTwiceError: (state) => {
      state.callInError = ''
    },
    resetCheckInPatientStatus: (state) => {
      state.checkInPatientStatus = APIStatus.idle
    },
  },
  extraReducers: (builder) => {
    //loadLobbyQueueAsync
    builder.addCase(loadLobbyQueueAsync.fulfilled, (state, action) => {
      state.queueStatus = APIStatus.fulfilled
      state.queue = action.payload
        .map((q: any) => {
          return {
            patientId: q.visit.patient.id,
            visitId: q.visit.id,
            profilePic: q.visit.patient.user.profilePic,
            firstname: q.visit.patient.user.firstname,
            lastname: q.visit.patient.user.lastname,
            gender: q.visit.patient.user.gender,
            screenerFeedback: q.visit.patient.screenerFeedback,
            dob: q.visit.patient.user.dob,
            appointmentTime: q.visit.appointmentTime,
            checkinTime: q.checkinTime,
            feedback: q.visit.patient.screenerFeedback,
            queueStatus: q.queueStatus,
            membership: q.membership,
            membershipStatus: q.membershipStatus,
            subscriptionExpiresAt: q.subscriptionExpiresAt,
            isNew: q.isNew,
            scanDue: q.scanDue,
          }
        })
        .sort((a: any, b: any) => {
          const checkinTimeA = new Date(a.checkinTime).getTime()
          const appointmentTimeA = new Date(a.appointmentTime).getTime()
          const checkinTimeB = new Date(b.checkinTime).getTime()
          const appointmentTimeB = new Date(b.appointmentTime).getTime()

          const threshold = 15 * 60 * 1000 // 15 minutes in milliseconds for grace time.

          const isALate = checkinTimeA > appointmentTimeA + threshold ? 1 : 0
          const isBLate = checkinTimeB > appointmentTimeB + threshold ? 1 : 0

          // Late entries go to the end
          if (isALate !== isBLate) {
            return isALate - isBLate
          }

          // For late entries, sort by check-in time
          if (isALate === 1) {
            return checkinTimeA - checkinTimeB
          }

          // Maintain original order for non-late entries (already sorted by appointmentTime)
          return 0
        })
      // .sort((a: any, b: any) => { // old version sorted by checkinTime
      //   const checkinTimeA = new Date(a.checkinTime).getTime()
      //   const checkinTimeB = new Date(b.checkinTime).getTime()
      //   return checkinTimeA - checkinTimeB
      // })
    })
    builder.addCase(loadLobbyQueueAsync.rejected, (state, action) => {
      state.queueStatus = APIStatus.rejected
    })
    builder.addCase(loadLobbyQueueAsync.pending, (state, action) => {
      state.queueStatus = APIStatus.pending
    })

    builder.addCase(pollLobbyQueueAsync.fulfilled, (state, action) => {
      //   state.queueStatus = APIStatus.fulfilled
      state.queue = action.payload
        .map((q: any) => {
          return {
            patientId: q.visit.patient.id,
            visitId: q.visit.id,
            profilePic: q.visit.patient.user.profilePic,
            firstname: q.visit.patient.user.firstname,
            lastname: q.visit.patient.user.lastname,
            gender: q.visit.patient.user.gender,
            screenerFeedback: q.visit.patient.screenerFeedback,
            dob: q.visit.patient.user.dob,
            appointmentTime: q.visit.appointmentTime,
            checkinTime: q.checkinTime,
            feedback: q.visit.patient.screenerFeedback,
            queueStatus: q.queueStatus,
            membership: q.membership,
            membershipStatus: q.membershipStatus,
            subscriptionExpiresAt: q.subscriptionExpiresAt,
            isNew: q.isNew,
            scanDue: q.scanDue,
          }
        })
        .sort((a: any, b: any) => {
          const checkinTimeA = new Date(a.checkinTime).getTime()
          const appointmentTimeA = new Date(a.appointmentTime).getTime()
          const checkinTimeB = new Date(b.checkinTime).getTime()
          const appointmentTimeB = new Date(b.appointmentTime).getTime()

          const threshold = 15 * 60 * 1000 // 15 minutes in milliseconds for grace time.

          const isALate = checkinTimeA > appointmentTimeA + threshold ? 1 : 0
          const isBLate = checkinTimeB > appointmentTimeB + threshold ? 1 : 0

          // Late entries go to the end
          if (isALate !== isBLate) {
            return isALate - isBLate
          }

          // For late entries, sort by check-in time
          if (isALate === 1) {
            return checkinTimeA - checkinTimeB
          }

          // Maintain original order for non-late entries (already sorted by appointmentTime)
          return 0
        })
      // .sort((a: any, b: any) => {
      //   const checkinTimeA = new Date(a.checkinTime).getTime()
      //   const checkinTimeB = new Date(b.checkinTime).getTime()
      //   return checkinTimeA - checkinTimeB
      // })
      const visitIdsToRemove = action.payload.map((item: any) => item.visit.id)
      const updatedVisits = state.todayVisit?.filter((v: any) => !visitIdsToRemove.includes(v.visitId))
      if (updatedVisits) {
        state.todayVisit = updatedVisits
      }
    })
    builder.addCase(pollLobbyQueueAsync.rejected, (state, action) => {
      //state.queueStatus = APIStatus.rejected
    })
    builder.addCase(pollLobbyQueueAsync.pending, (state, action) => {
      //  state.queueStatus = APIStatus.pending
    })

    //loadLobbyPreviousAsync
    builder.addCase(loadLobbyPreviousAsync.fulfilled, (state, action) => {
      state.previousPatientsStatus = APIStatus.fulfilled
      state.previousPatients = action.payload
        .map((q: any) => {
          return {
            patientId: q.visit.patient.id,
            visitId: q.visit.id,
            profilePic: q.visit.patient.user.profilePic,
            firstname: q.visit.patient.user.firstname,
            lastname: q.visit.patient.user.lastname,
            gender: q.visit.patient.user.gender,
            screenerFeedback: q.visit.patient.screenerFeedback,
            dob: q.visit.patient.user.dob,
            appointmentTime: q.visit.appointmentTime,
            checkinTime: q.checkinTime,
            serviceStartTime: q.visit.serviceStartTime,
            serviceEndTime: q.visit.serviceEndTime,
            feedback: q.visit.patient.screenerFeedback,
            membership: q.membership,
            membershipStatus: q.membershipStatus,
            subscriptionExpiresAt: q.subscriptionExpiresAt,
            isNew: q.isNew,
          }
        })
        .sort((a: any, b: any) => {
          const serviceEndTimeA = a.serviceEndTime ? new Date(a.serviceEndTime).getTime() : null
          const serviceEndTimeB = b.serviceEndTime ? new Date(b.serviceEndTime).getTime() : null

          // Handle null values
          if (serviceEndTimeA === null && serviceEndTimeB === null) return 0 // Both null, maintain order
          if (serviceEndTimeA === null) return 1 // A is null, move it to the end
          if (serviceEndTimeB === null) return -1 // B is null, move it to the end

          // Sort normally in descending order
          return serviceEndTimeB - serviceEndTimeA
        })
      // .sort((a: any, b: any) => {
      //   const checkinTimeA = new Date(a.checkinTime).getTime()
      //   const checkinTimeB = new Date(b.checkinTime).getTime()
      //   return checkinTimeB - checkinTimeA
      // })
    })
    builder.addCase(loadLobbyPreviousAsync.rejected, (state, action) => {
      state.previousPatientsStatus = APIStatus.rejected
    })
    builder.addCase(loadLobbyPreviousAsync.pending, (state, action) => {
      state.previousPatientsStatus = APIStatus.pending
    })

    //callInNextPatientAsync
    builder.addCase(callInNextPatientAsync.fulfilled, (state, action) => {
      state.callInPatientStatus = APIStatus.fulfilled
      state.queue = action.payload.queue
      state.currentPatient = action.payload.nextPatient
    })
    builder.addCase(callInNextPatientAsync.rejected, (state, action: any) => {
      state.callInPatientStatus = APIStatus.rejected
      if (action.payload.error === 'calledInTwice') {
        state.callInError = action.payload.error
        state.queue = action.payload.queue
      }
    })
    builder.addCase(callInNextPatientAsync.pending, (state, action) => {
      state.callInPatientStatus = APIStatus.pending
    })

    //patientVisitAsync
    builder.addCase(patientVisitAsync.fulfilled, (state, action) => {
      state.visitStatus = APIStatus.fulfilled
      state.visit = action.payload
    })
    builder.addCase(patientVisitAsync.rejected, (state, action) => {
      state.visitStatus = APIStatus.rejected
    })
    builder.addCase(patientVisitAsync.pending, (state, action) => {
      state.visitStatus = APIStatus.pending
    })

    //startChiropractic
    builder.addCase(startChiropracticAsync.fulfilled, (state, action) => {
      state.startChiropracticStatus = APIStatus.fulfilled

      const res = {
        patientId: action.payload.visit?.patientId,
        visitId: action.payload.visit?.id,
        profilePic: action.payload.visit?.patient?.user?.profilePic,
        firstname: action.payload.visit?.patient?.user?.firstname,
        lastname: action.payload.visit?.patient?.user?.lastname,
        gender: action.payload.visit?.patient?.user?.gender,
        screenerFeedback: action.payload.visit?.patient?.screenerFeedback,
        dob: action.payload.visit?.patient?.user?.dob,
        appointmentTime: action.payload.visit?.appointmentTime,
        checkinTime: action.payload.checkinTime,
        queueStatus: action.payload.queueStatus,
        notesUploadUrl: action.payload.notesUploadUrl,
      }
      state.currentPatient = res
    })
    builder.addCase(startChiropracticAsync.rejected, (state, action) => {
      state.startChiropracticStatus = APIStatus.rejected
    })
    builder.addCase(startChiropracticAsync.pending, (state, action) => {
      state.startChiropracticStatus = APIStatus.pending
    })

    //stopChiropractic
    builder.addCase(stopChiropracticAsync.fulfilled, (state, action) => {
      state.stopChiropracticStatus = APIStatus.fulfilled
      const res = {
        ...state.currentPatient,
        patientId: action.payload.visit?.patientId,
        visitId: action.payload.visit?.id,
        profilePic: action.payload.visit?.patient?.user?.profilePic,
        firstname: action.payload.visit?.patient?.user?.firstname,
        lastname: action.payload.visit?.patient?.user?.lastname,
        gender: action.payload.visit?.patient?.user?.gender,
        screenerFeedback: action.payload.visit?.patient?.screenerFeedback,
        dob: action.payload.visit?.patient?.user?.dob,
        appointmentTime: action.payload.visit?.appointmentTime,
        checkinTime: action.payload.checkinTime,
        queueStatus: action.payload.queueStatus,
      }
      state.currentPatient = res
    })
    builder.addCase(stopChiropracticAsync.rejected, (state, action) => {
      state.stopChiropracticStatus = APIStatus.rejected
    })
    builder.addCase(stopChiropracticAsync.pending, (state, action) => {
      state.stopChiropracticStatus = APIStatus.pending
    })

    //patientQuestionarieAsync
    builder.addCase(patientQuestionarieAsync.fulfilled, (state, action) => {
      state.patientQuestionarieStatus = APIStatus.fulfilled
      state.patientQuestionaries = action.payload.map((q: IQandAPayload) => {
        return {
          type: q.question.type,
          iconIdentifier: q.question.iconIdentifier,
          order: q.responseOptions.map((od) => {
            return od.option.order.toString()
          }),
          optionText: q.responseOptions.map((o: ResponseOption) => {
            return o.option.text
          }),
          optionId: q.responseOptions.map((i) => {
            return i.optionId
          }),
        }
      })
    })
    builder.addCase(patientQuestionarieAsync.rejected, (state, action) => {
      state.patientQuestionarieStatus = APIStatus.rejected
    })
    builder.addCase(patientQuestionarieAsync.pending, (state, action) => {
      state.patientQuestionarieStatus = APIStatus.pending
    })

    //getTodayVisitAsync

    builder.addCase(getTodayVisitAsync.fulfilled, (state, action) => {
      state.todayVisitStatus = APIStatus.fulfilled
      state.todayVisit = action.payload
        .map((q: any) => {
          return {
            patientId: q.patient.id,
            visitId: q.id,
            clinicId: q.clinicId,
            profilePic: q.patient.user.profilePic,
            initialWaiver: q.initialWaiver,
            isQuestionareCompleted: q.patient.isQuestionareCompleted,
            firstname: q.patient.user.firstname,
            lastname: q.patient.user.lastname,
            gender: q.patient.user.gender,
            dob: q.patient.user.dob,
            appointmentTime: q.appointmentTime,
            checkinTime: q.checkinTime,
            membership: q.membership,
            membershipStatus: q.membershipStatus,
            subscriptionExpiresAt: q.subscriptionExpiresAt,
            isNew: q.isNew,
            scanDue: q.scanDue,
          }
        })
        .sort((a: any, b: any) => {
          const appointmentTimeA = new Date(a.appointmentTime).getTime()
          const appointmentTimeB = new Date(b.appointmentTime).getTime()
          return appointmentTimeA - appointmentTimeB
        })
    })
    builder.addCase(getTodayVisitAsync.rejected, (state, action) => {
      state.todayVisitStatus = APIStatus.rejected
    })
    builder.addCase(getTodayVisitAsync.pending, (state, action) => {
      state.todayVisitStatus = APIStatus.pending
    })

    // postTreatmentType
    builder.addCase(postTreatmentType.fulfilled, (state, action) => {
      state.treatmentTypeStatus = APIStatus.fulfilled
      state.treatmentType = action.payload
      if (state.visit) {
        state.visit.treatmentType = action.payload.treatmentType
      }
    })
    builder.addCase(postTreatmentType.rejected, (state, action) => {
      state.treatmentTypeStatus = APIStatus.rejected
    })
    builder.addCase(postTreatmentType.pending, (state, action) => {
      state.treatmentTypeStatus = APIStatus.pending
    })

    //patientVisitAsync
    builder.addCase(patientVisitModalAsync.fulfilled, (state, action) => {
      state.visitModalStatus = APIStatus.fulfilled
      state.visitModal = action.payload
    })
    builder.addCase(patientVisitModalAsync.rejected, (state, action) => {
      state.visitModalStatus = APIStatus.rejected
    })
    builder.addCase(patientVisitModalAsync.pending, (state, action) => {
      state.visitModalStatus = APIStatus.pending
    })

    //patientQuestionarieAsync
    builder.addCase(patientQuestionarieModalAsync.fulfilled, (state, action) => {
      state.patientQuestionarieModalStatus = APIStatus.fulfilled
      state.patientQuestionariesModal = action.payload.map((q: IQandAPayload) => {
        return {
          type: q.question.type,
          iconIdentifier: q.question.iconIdentifier,
          order: q.responseOptions.map((od) => {
            return od.option.order.toString()
          }),
          optionText: q.responseOptions.map((o: ResponseOption) => {
            return o.option.text
          }),
          optionId: q.responseOptions.map((i) => {
            return i.optionId
          }),
        }
      })
    })
    builder.addCase(patientQuestionarieModalAsync.rejected, (state, action) => {
      state.patientQuestionarieModalStatus = APIStatus.rejected
    })
    builder.addCase(patientQuestionarieModalAsync.pending, (state, action) => {
      state.patientQuestionarieModalStatus = APIStatus.pending
    })

    // checkInPatientAsync
    builder.addCase(checkInPatientAsync.fulfilled, (state, action) => {
      state.checkInPatientStatus = APIStatus.fulfilled
      state.checkInPatient = action.payload
    })
    builder.addCase(checkInPatientAsync.rejected, (state, action) => {
      state.checkInPatientStatus = APIStatus.rejected
    })
    builder.addCase(checkInPatientAsync.pending, (state, action) => {
      state.checkInPatientStatus = APIStatus.pending
    })
  },
})

export const {
  resetStartChiropracticStatus,
  resetStopChiropracticStatus,
  resetCallInPatientStatus,
  resetCalledInTwiceError,
  resetCheckInPatientStatus,
} = lobbySlice.actions
export default lobbySlice.reducer
